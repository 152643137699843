import {
  isProdGuestEnv,
  isProdEnv,
  isStagingEnv,
  isQAEnv,
  isQaGuestEnv,
  isStagingGuestEnv
} from './envUtils';

export function isHubspotEnabled() {
  return (
    isProdGuestEnv() ||
    isProdEnv() ||
    isStagingEnv() ||
    isQAEnv() ||
    isQaGuestEnv() ||
    isStagingGuestEnv()
  );
}
