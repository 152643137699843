import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Wrapper, Logo, LoginForm } from '../../css/components/Modules/login';
import { PrimaryButton, SecondaryButton } from '../../css/components/Modules/Buttons';
import { Form, FormGroup, Row, Input } from 'reactstrap';
import * as Yup from 'yup';
import APIUtil from '../../api/apiUtil';
import { Field, Formik, getIn } from 'formik';
import InputField from 'components/FormElements/InputField';
import { errorAlert } from '../../utils/alert';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { isUserAuthenticated } from '../../services/localStorage';
import { Redirect } from 'react-router-dom';
import { isTablet } from 'react-device-detect';
import CommonModal from 'components/Modals/CommonModal';
import { phoneValidator } from '../../helpers/validator';

import InputMask from 'react-input-mask';
import Label from 'reactstrap/lib/Label';
import errorMessage from 'components/FormElements/ErrorMessages';
import { formatPhoneNumber } from 'helpers/phoneNumberFormatter';
import ReCaptcha from 'components/Recaptcha';
import PrivacyPolicyCheckbox from 'components/PrivacyPolicyCheckbox';
import appConfig from '../../config';
import { embedHubspot, removeHubspot } from 'helpers/hubspot';
import { isHubspotEnabled } from 'utils/isHubspotEnabled';
import { signIn } from 'aws-amplify/auth';
import { currentSession } from 'services/amplify/currentSession';
import { validateEmail } from 'helpers/YUPValidations/validateEmail';

const api = new APIUtil();
const guestLoginSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('*Required')
    .min(2, 'First Name should be between 2 and 30 characters')
    .max(30, 'First Name should be between 2 and 30 characters'),
  lastName: Yup.string()
    .required('*Required')
    .min(2, 'Last Name should be between 2 and 30 characters')
    .max(30, 'Last Name should be between 2 and 30 characters'),
  email: validateEmail(),
  mobilePhoneNumber: phoneValidator()
});
const GuestLogin = props => {
  const [showOrientationModal, setShowOrientationModal] = useState(false);
  const [isPrivacyPolicyChecked, setPrivacyPolicyStatus] = useState(false);
  const formikRef = useRef(null);

  useEffect(() => {
    //adding hubspot on mount only for prod env
    if (isHubspotEnabled()) {
      embedHubspot();
    }
    // removing hubspot on unmount
    return () => removeHubspot();
  }, []);

  useLayoutEffect(() => {
    if (isTablet && window.innerHeight > window.innerWidth) {
      setShowOrientationModal(true);
    }

    window.addEventListener('resize', function hideGuestModal() {
      if (isTablet && window.innerHeight < window.innerWidth) {
        setShowOrientationModal(false);
        window.removeEventListener('resize', hideGuestModal);
      }
    });
  }, []);

  const toggleOrientaionModal = () => {
    setShowOrientationModal(!showOrientationModal);
  };

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    mobilePhoneNumber: ''
  };

  const privacyPolicyStatusHandler = isChecked => {
    setPrivacyPolicyStatus(isChecked);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    // if captcha exists, execute it else just submit the function.//fix
    if (
      appConfig.config().env !== 'staging' &&
      appConfig.config().env !== 'qa' &&
      window.grecaptcha &&
      window.grecaptcha.execute
    ) {
      window.grecaptcha.execute();
    } else {
      guestLoginSubmitHandler(values, setSubmitting);
    }
  };

  const afterCaptcha = (hasError = false, resetCaptcha = true) => {
    if (formikRef.current) {
      if (hasError) {
        formikRef.current.setSubmitting(false);
        resetCaptcha && window.grecaptcha && window.grecaptcha.reset();
      } else {
        guestLoginSubmitHandler(formikRef.current.state.values, formikRef.current.setSubmitting);
      }
    }
  };

  const guestLoginSubmitHandler = async (values, setSubmitting) => {
    setSubmitting(true);
    try {
      const randomPassword = `${Math.random().toString(36).slice(-8)}12ASS@@`;
      const { data } = await api.post('fp/login', {
        FirstName: values.firstName.trim(),
        LastName: values.lastName.trim(),
        Email: values.email.trim(),
        PhoneNumber: values.mobilePhoneNumber ? formatPhoneNumber(values.mobilePhoneNumber) : '',
        Password: randomPassword
      });

      await signIn({
        username: data.EmailAddress,
        password: randomPassword,
        options: {
          authFlowType: 'USER_PASSWORD_AUTH'
        }
      });
      const { idToken, accessToken } = await currentSession();
      const dataWithGuestFlag = {
        ...data,
        cognitoTokens: {
          idToken,
          accessToken
        },
        isGuest: true
      };
      props.onAuth(dataWithGuestFlag);
      props.history.push('/dashboard/schedule?guestLoginSuccess=true');
    } catch (error) {
      if (isUserAuthenticated()) {
        props.onLogout(); // if session api throws error post cognito login, log user out
      }
      setSubmitting(false);
      if (error.response) {
        errorAlert(error.response?.data?.error);
        return;
      }
      errorAlert('Something Went Wrong!');
      throw error;
    }
  };

  let authRedirect = null;
  if (props.isAuthenticated && isUserAuthenticated()) {
    authRedirect = <Redirect to='/dashboard' />;
  }
  return (
    <>
      <Wrapper className='guest-login'>
        <CommonModal
          toggle={toggleOrientaionModal}
          isOpen={showOrientationModal}
          className='orientation-notification'
          wrapClassName='full-backdrop'
          backdrop={true}
        >
          <p className='text-start'>
            Please flip your device to landscape for an optimum experience.
          </p>
          <div className='text-end'>
            <SecondaryButton modifiers='small' onClick={toggleOrientaionModal}>
              Got it!
            </SecondaryButton>
          </div>
        </CommonModal>
        <Row className='login-row login-row--guest'>
          <div className='d-flex flex-column auth-col'>
            <Logo padding={'40px 50px'} className='mb-0'>
              <span>
                <a href='https://horizon360.toro.com' target='_parent' rel='noopener noreferrer'>
                  <img
                    className='img-fluid'
                    src={require('../../images/logo_dark.svg').default}
                    alt='Horizon 360 Logo'
                  />
                </a>
              </span>
            </Logo>
            <LoginForm className='text-center '>
              <div className='login-form-guestwrapper'>
                <div className='login-form-container'>
                  <h1 className='demo-header'>
                    Horizon360<sup>TM</sup>
                  </h1>
                  <h2 className='demo-subheader'>Demo Site</h2>
                  {authRedirect}
                  <Formik
                    ref={formikRef}
                    initialValues={initialValues}
                    validationSchema={guestLoginSchema}
                    onSubmit={handleSubmit}
                    id='demo-site'
                    render={props => {
                      const { handleSubmit, isSubmitting, touched, errors } = props;
                      return (
                        <>
                          <Form id='demo-site' onSubmit={handleSubmit}>
                            <FormGroup className='form-group'>
                              <Field
                                type='text'
                                name='firstName'
                                label='First Name*'
                                autoComplete='off'
                                component={InputField}
                                autoFocus={true}
                              />
                            </FormGroup>
                            <FormGroup className='form-group'>
                              <Field
                                type='text'
                                name='lastName'
                                label='Last Name*'
                                autoComplete='off'
                                component={InputField}
                              />
                            </FormGroup>
                            <FormGroup className='form-group'>
                              <Field
                                type='email'
                                name='email'
                                label='Email Address*'
                                autoComplete='off'
                                component={InputField}
                              />
                            </FormGroup>
                            <FormGroup className='form-group'>
                              <Field
                                name='mobilePhoneNumber'
                                render={({ field }) => {
                                  return (
                                    <>
                                      <Label>Phone Number(Mobile)</Label>
                                      <Input
                                        type='tel'
                                        mask='(999) 999-9999'
                                        maskChar=' '
                                        tag={InputMask}
                                        {...field}
                                        invalid={
                                          (touched[field.name] && !!errors[field.name]) ||
                                          (getIn(errors, field.name) && getIn(touched, field.name))
                                        }
                                        onChange={e => {
                                          props.setFieldValue(field.name, e.target.value);
                                        }}
                                      />
                                      {errorMessage(touched, errors, field)}
                                    </>
                                  );
                                }}
                              />
                            </FormGroup>
                            <PrivacyPolicyCheckbox
                              onStatusChangeHandler={privacyPolicyStatusHandler}
                            />
                            <div className='submit mt-0 mt-md-5'>
                              {appConfig.config().env !== 'staging' &&
                              appConfig.config().env !== 'qa' ? (
                                <ReCaptcha postVerificationCallback={afterCaptcha} />
                              ) : null}
                              <PrimaryButton
                                type='submit'
                                disabled={isSubmitting || !isPrivacyPolicyChecked}
                                modifiers={'medium'}
                              >
                                Start Demo
                              </PrimaryButton>
                            </div>
                          </Form>
                        </>
                      );
                    }}
                  />
                </div>
              </div>
            </LoginForm>
          </div>
          <div className='login-bottom'></div>
        </Row>
      </Wrapper>
    </>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

const mapDispatchToProps = dispatch => ({
  onAuth: token => dispatch(actions.authSuccess(token)),
  onLogout: () => dispatch(actions.authLogout())
});

export default connect(mapStateToProps, mapDispatchToProps)(GuestLogin);
