import styled from 'styled-components';
import { theme, typeScale } from '../../utils';
import { applyStyleModifiers } from 'styled-components-modifiers';

const HEADING_MODIFIERS = {
  uppercase: () => `
    text-transform: uppercase
  `,
  small: () => `
     ${typeScale.textSm}
  `
};

export const AuthHeader = styled.h1`
  ${typeScale.headerAuthMobile};
  font-weight: bold;
  @media (min-width: 768px) {
    ${typeScale.headerAuth};
  }
  ${applyStyleModifiers(HEADING_MODIFIERS)};
`;
export const GreySubHeading = styled.h5`
  color: ${theme.textFaded};
  ${typeScale.textMd};
  font-weight: bold;
  letter-spacing: 0.5px;
  ${applyStyleModifiers(HEADING_MODIFIERS)};
`;
export const ModalHeading = styled.h1`
  margin-bottom: 0;
  ${typeScale.header1}
  ${applyStyleModifiers(HEADING_MODIFIERS)};
`;
export const PageHeading = styled.h1`
  ${typeScale.header1};
  color: ${theme.textColor};
  white-space: nowrap;
  margin-bottom: 0;
  ${applyStyleModifiers(HEADING_MODIFIERS)};
`;
// can be called SecondaryHeading?
export const SectionHeading = styled.h3`
  ${typeScale.textMd};
  color: ${theme.textPrimary};
  font-weight: bold;
  ${applyStyleModifiers(HEADING_MODIFIERS)};
`;

export const H2 = styled.h2`
  ${typeScale.textLg};
  color: ${theme.textPrimary};
  font-weight: bold;
  ${applyStyleModifiers(HEADING_MODIFIERS)};
`;
