export const embedHubspot = () => {
  // hubspot script
  const hubspotScript = document.createElement('script');
  hubspotScript.src = '//js.hs-scripts.com/111368.js';
  hubspotScript.defer = true;
  hubspotScript.async = true;
  hubspotScript.id = 'hs-script-loader';
  document.body.appendChild(hubspotScript);
};

export const removeHubspot = () => {
  //remove hubspot script.
  const element = document.getElementById('hs-script-loader');
  element && element.remove();
};
